import React from 'react';
import {
  Link
} from "react-router-dom";

function headerButtons(props) {
  return (
  <div className={`c-button-group ${props.class || ''}`}>
    <Link to="/buildYourCrew"  className="button primary c-header__button">{props.text || 'Get Started'}</Link>
    <a href="https://calendly.com/crewforall/demo"  target="_blank" rel="noopener noreferrer" className="c-header__button button primary-inverse">Join a Demo</a>
  </div>
  )
}


export default headerButtons;
